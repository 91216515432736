import { Suspense, useEffect } from 'react'
import TagManager from 'react-gtm-module';

import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { appWithTranslation } from 'next-i18next';

import SEO from '../next-seo.config';

import 'bootstrap/dist/css/bootstrap.min.css';
import './globals.css'
import 'styles/main.scss';

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-PL9SND8',
    });
  }, [])

  return (
     <Suspense>
      <DefaultSeo {...SEO} />
      <div className="min-h-screen md:grid">
        <Component {...pageProps} />
      </div>
    </Suspense>   
  );
};

export default appWithTranslation(App)
