module.exports =  {
  title: "Lead Software Engineer",
  titleTemplate: `%s | Dmitrii Shmakov`,
  defaultTitle: "Lead Software Engineer | Dmitrii Shmakov",
  // eslint-disable-next-line max-len
  description: 'Experienced lead software engineer with 10 years of proven success leading high-performing teams and delivering quality web- software products at all stages of the development cycle and covering by tests up to 100%. With a solid foundation in frontend engineering, I possess a robust technical background and effective communication with teams. Now I’m interested in finding new opportunities to create web-application based on React/Typescript.',
  themeColor: "#02aab0", 
  // eslint-disable-next-line max-len
  keywords: `Dmitrii Shmakov, 
  technical background,
  quality web-software product,
  effective communication,
  lead software engineer,
  solid foundation,
  development cycle,
  frontend engineering,
  technical background`,
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'apple-touch-icon',
      href: '/icon-512x512.png',
      sizes: '512x512'
    },
    {
      rel: 'icon',
      href: 'icon-512x512.png',
      sizes: '512x512'
    },
    {
      rel: 'manifest',
      href: '/manifest.json'
    },
  ],
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://shmakovdima.ru',
    // eslint-disable-next-line max-len
    description: 'Experienced lead software engineer with 10 years of proven success leading high-performing teams and delivering quality web- software products at all stages of the development cycle and covering by tests up to 100%. With a solid foundation in frontend engineering, I possess a robust technical background and effective communication with teams. Now I’m interested in finding new opportunities to create web-application based on React/Typescript.',
    title: 'Dmitrii Shmakov | Lead software engineer',
    images: [
      {
        url: '/og-image.jpg', 
        width: 6000,
        height: 4000,
        type: 'image/jpeg',
        alt: 'Dmitrii Shmakov | Lead software engineer',
        secureUrl: 'https://www.shmakovdima.ru/og-image.jpg',
      },
    ],
    profile: {
      firstName: "Dmitrii",
      lastName: "Shmakov",
      username: "shmakovdima"
    }
  },
  twitter: {
    handle: '@shmakovdima',
    site: 'https://shmakovdima.ru',
    cardType: 'summary_large_image',
  },
}